<template>

  <el-container>
    <el-header>
      门店设置
    </el-header>
    <el-main class="main">

      <div class="content">
        <div class="store-set">
          <div class="cell-t">
            <div class="title">
              开启排班
            </div>
            <div class="value">
              <el-switch v-model="switchValue1" active-color="#409eff">
              </el-switch>
            </div>
          </div>
          <div class="cell-t">
            <div class="title">
              打印交班结果
            </div>
            <div class="value">
              <el-switch v-model="switchValue2" active-color="#409eff">
              </el-switch>
            </div>
          </div>
          <div class="cell-t">
            <div class="title">
              客显
            </div>
            <div class="value">
              <el-switch v-model="switchValue3" active-color="#409eff">
              </el-switch>
            </div>
          </div>
          <div class="cell-one">
            <div class="title">
              小票打印张数
            </div>
            <div class="value">
              <div :class="num===i?'btn btn1':'btn'" v-for="(item,i) in 4" :key="i" @click="num=i">
                <span v-if="item!==4">{{item}}张</span>
                <span v-else>不打印</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>

</template>

<script>
  export default {

    data() {
      return {
        // 门店设置
        switchValue1: true,
        switchValue2: true,
        switchValue3: true,
        num: 0,
      }
    },
    methods: {

    }
  }

</script>

<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .store-set {
      .cell-t {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 2px #ccc;
        padding: 10px 20px;
        background-color: #fff;
        margin-bottom: 15px;

        .title {
          line-height: 24px;
        }

      }

      .cell-one {
        background-color: #fff;

        .title {
          padding: 10px 20px;
          border-bottom: 1px solid #f2f2f2;
        }

        .value {
          padding: 10px 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .btn {
            width: 100px;
            text-align: center;
            line-height: 30px;
            color: #666;
            font-size: 12px;
            margin: 0 15px;
            border: 1px solid #e9e9e9;
          }

          .btn1 {
            background-color: #f6faff;
            color: #409eff;
            border-color: #a0cfff;
          }

        }
      }
    }
  }

</style>
